<!--
 * @Description: 服务商品详情
 * @Author: ChenXueLin
 * @Date: 2021-11-15 23:11:29
 * @LastEditTime: 2022-01-12 17:27:23
 * @LastEditors: ChenXueLin
-->
<!-- 基本信息 -->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start-->
          <template>
            <div class="baseInfo-box">
              <div class="edit-title">商品基础信息</div>
              <el-form
                label-width="110px"
                :inline="true"
                :model="baseInfoDetail"
              >
                <el-form-item label="商品ID" prop="thirdClassId">
                  <el-input
                    placeholder="商品ID"
                    v-model="baseInfoDetail.thirdClassId"
                  ></el-input>
                </el-form-item>

                <el-form-item label="商品分类" prop="firstClassId">
                  <e6-vr-select
                    v-model="baseInfoDetail.firstClassId"
                    :data="typeList"
                    placeholder="商品分类"
                    title="商品分类"
                    clearable
                  ></e6-vr-select>
                </el-form-item>

                <el-form-item label="商品开票名称" prop="secondClassId">
                  <e6-vr-select
                    v-model="baseInfoDetail.secondClassId"
                    :data="typeList"
                    placeholder="商品开票名称"
                    title="商品开票名称"
                    clearable
                  ></e6-vr-select>
                </el-form-item>

                <el-form-item prop="thirdClassId" label="商品名称">
                  <e6-vr-select
                    v-model="baseInfoDetail.thirdClassId"
                    :data="typeList"
                    placeholder="商品名称"
                    title="商品名称"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item prop="usedState" label="商品可用状态">
                  <e6-vr-select
                    v-model="baseInfoDetail.usedState"
                    :data="typeList"
                    placeholder="商品可用状态"
                    title="商品可用状态"
                    clearable
                  ></e6-vr-select>
                </el-form-item>

                <el-form-item prop="meaurement" label="单位">
                  <e6-vr-select
                    v-model="baseInfoDetail.meaurement"
                    :data="typeList"
                    placeholder="单位"
                    title="单位"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item prop="remark" label="备注">
                  <el-input
                    placeholder="备注"
                    v-model="baseInfoDetail.remark"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <!-- 基本信息 end  -->
          </template>
          <!-- 基本信息  end -->
          <div class="baseInfo-box">
            <div class="edit-title">商品扩展信息</div>
            <template>
              <el-form
                label-width="130px"
                :inline="true"
                :model="baseInfoDetail"
                class="editForm"
              >
                <el-form-item prop="shelfStatus" label="上架状态">
                  <e6-vr-select
                    v-model="baseInfoDetail.shelfStatus"
                    :data="typeList"
                    placeholder="上架状态"
                    title="上架状态"
                    clearable
                  ></e6-vr-select>
                </el-form-item>

                <el-form-item prop="removeReson" label="下架原因">
                  <el-input
                    placeholder="下架原因"
                    v-model="baseInfoDetail.removeReson"
                  ></el-input>
                </el-form-item>
                <el-form-item label="商品简介" prop="productDescription">
                  <el-input
                    style="width:400px"
                    v-model="baseInfoDetail.productDescription"
                    type="textarea"
                    :rows="4"
                    placeholder="请输入内容"
                  ></el-input>
                </el-form-item>
              </el-form>
            </template>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { softwareGoodsDetail } from "@/api";
import { printError } from "@/utils/util";

export default {
  name: "",
  data() {
    return {
      baseInfoDetail: {}
    };
  },
  mixins: [base],
  computed: {},
  components: {},
  watch: {},
  created() {},
  methods: {
    //获取详情
    async getDetail() {
      try {
        let res = await softwareGoodsDetail();
        this.baseInfoDetail = this.getFreezeResponse(res, {
          path: "data"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
